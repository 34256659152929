<template>
  <div>
    <header>
      <div class="header-text">
        Classroom Proficiency Assessment Tool
      </div>
    </header>

    <div class="container-one">
      <div class="image">
        <img src="@/assets/images/pages/login/loginPageLogo.png" alt="Teacher Proficiency Evaluation System" />
      </div>
      <div class="form">
        <validation-observer ref="loginValidation" #default="{invalid}">
          <b-form @submit.prevent>
            <!-- email -->
            <b-overlay :show="show" rounded="sm" opacity="0.2" no-fade>
              <b-form-group label-for="email" label="Email Address*">
                <validation-provider #default="{ errors }" name="Email" rules="required|email">
                  <b-form-input id="email" v-model="userEmail" name="login-email" :state="errors.length > 0 ? false : null" placeholder="john@example.com" autofocus />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="password">Password*</label>
                </div>
                <validation-provider #default="{ errors }" name="Password" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input id="password" v-model="password" :type="passwordFieldType" class="form-control-merge" :state="errors.length > 0 ? false : null" name="login-password" placeholder="Password" />

                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <template v-slot:overlay>
                <div class="d-flex align-items-center">
                  <b-spinner small type="grow" variant="secondary" />
                  <b-spinner type="grow" variant="dark" />
                  <b-spinner small type="grow" variant="secondary" />
                  <!-- We add an SR only text for screen readers -->
                  <span class="sr-only">Please wait...</span>
                </div>
              </template>

              <!-- submit button -->
              <b-button variant="warning" type="submit" style="height:50px; color:#0B4666 !important; border-color:#187FA4 !important;" @click="login" block :disabled="invalid"> LOG IN </b-button>
            </b-overlay>
          </b-form>

          <b-alert variant="info" class="mt-2" show>
            <div class="alert-body">
              <span><strong>Email Address: </strong> <small class="text-primary">admin@admin.com</small></span>
              <br />
              <span><strong>Password: </strong> <small class="text-primary">admin</small></span>
            </div>
          </b-alert>
        </validation-observer>
      </div>
    </div>

    <div class="container-two">
      <div style="justify-content: center;" class="row text-center">
        <i class="fa-solid fa-handshake text-primary text-center">
          <p>COLLABORATION</p>
        </i>

        <i class="fa-solid fa-plus icon" style="color:#828282;"></i>

        <i class="fa-solid fa-comment-dots text-primary text-center">
          <p>FEEDBACK</p>
        </i>

        <i class="fa-solid fa-plus icon" style="color:#828282;"></i>

        <i class="fa-solid fa-child-reaching text-primary text-center">
          <p>GROWTH</p>
        </i>

        <i class="fa-solid fa-equals icon" style="color:#828282;"></i>

        <h1 style="color:#0B4666;">
          RC EDUCATOR <br />
          CERTIFICATION
        </h1>
      </div>
    </div>

    <footer>
      <div class="footer-content">
        <div class="footer-copyright-text">
          Copyright ©2023 Systems Tailor Made All rights reserved. <br />
          www.systemstailormade.com
        </div>
        <div class="footer-cpat-text">
          STM
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import VuexyLogo from '@core/layouts/components/Logo.vue';
import { BSpinner, BOverlay, BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BIcon } from 'bootstrap-vue';
import { required, email } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import store from '@/store/index';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import axiosIns from '@/libs/axios';
import router from '@/router';
import useJwt from '@/auth/jwt/useJwt';

export default {
  components: {
    BSpinner,
    BOverlay,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: 'admin',
      userEmail: 'admin@admin.com',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      show: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
  },
  methods: {
    login() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.show = true;
          useJwt
            .login({
              email: this.userEmail,
              password: this.password,
            })
            .then((response) => {
              /*  if (response.data.userData.id == 1) {
                  response.data.userData.ability = [
                    {
                      action: "manage",
                      subject: "all",
                    },
                  ];
                } else {
                  response.data.userData.ability = [
                    {
                      action: "read",
                      subject: "Auth",
                    },
                  ];
                }*/

              if (response.data.error == 'Unauthorized') {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Error`,
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: `Incorrect login details, please try again.`,
                  },
                });
                this.show = false;
              } else {
                const { userData } = response.data;

                useJwt.setToken(response.data.accessToken);
                useJwt.setRefreshToken(response.data.refreshToken);
                localStorage.setItem('userData', JSON.stringify(userData));
                localStorage.setItem('userPerms', JSON.stringify(response.data.userPerms));

                /* this.$ability.update(userData.ability);*/
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Welcome ${userData.name || userData.surname}`,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                  },
                });

                this.show = false;
                router.push({ path: '/' });
              }
            })
            .catch((error) => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: `Error occurred while logging in`,
                },
              });
              this.show = false;
              this.$refs.loginValidation.setErrors(error.response.data.error);
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.container-one {
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 65vh;
}

.container-two {
  background-color: white;
  display: block;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: auto;
  min-height: 35vh;
  place-content: center;
  padding: 20px;
}

.container-two i {
  font-size: 6vw;
  margin-right: 5px;
  margin-left: 5px;
}

.container-two .icon {
  font-size: 2.3rem;
}

.container-two p {
  font-size: 2.2vw;
}

.container-two h1 {
  font-size: 2.5vw;
  margin-left: 5px;
}

@media screen and (min-width: 768px) {
  .container-two i {
    font-size: 3.3rem;
    margin-right: 15px;
    margin-left: 15px;
  }

  .container-two .icon {
    font-size: 3.3rem;
    margin-right: 15px;
    margin-left: 15px;
    margin-top: 15px;
  }

  .container-two p {
    font-size: 1.2rem;
    margin-top: 15px;
  }

  .container-two h1 {
    font-size: 2.1rem;
    margin-top: 2px;
    margin-left: 15px;
  }
}

.image {
  margin-top: 100px;
  flex: 6;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
}

@media screen and (max-width: 768px) {
  .image {
    display: none;
  }
}

.form {
  margin-top: 100px;
  flex: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* padding: 40px; */
}

.form input[type='text'] {
  display: block;
  width: 400px;
  height: 50px;
}

.form input[type='password'] {
  display: block;
  height: 50px;
}

.form input[type='button'] {
  display: block;
  height: 50px;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 8%;
  text-align: center;
  padding-top: 1%;
  background-color: #0b4666;
  color: #ffc104;
  box-shadow: 0px 1px 10px black;
}

.header-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2.2vw;
  text-align: center;
  font-family: 'Myriad Pro Semibold';
}

@media (max-width: 768px) {
  header {
    height: 10%;
    padding-top: 2%;
  }

  .header-text {
    font-size: 5vw;
  }
}

@media (max-width: 480px) {
  header {
    height: 12%;
    padding-top: 3%;
  }

  .header-text {
    font-size: 7vw;
  }
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 8%;
  background-color: #0b4666;
  color: #edf7fa;
  text-align: center;
}

.footer-content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.footer-copyright-text {
  position: absolute;
  text-align: center;
  font-family: 'Myriad Pro Semibold';
  font-size: 1.3em;
}

.footer-cpat-text {
  position: absolute;
  right: 0;
  margin-right: 25px;
  text-decoration: underline;
  font-family: 'Myriad Pro Semibold';
  font-size: 2.7em;
}

@media (max-width: 767.98px) {
  .footer-copyright-text {
    font-size: 0.7em;
  }

  .footer-cpat-text {
    font-size: 1.7em;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
